/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUploader = /* GraphQL */ `
  mutation CreateUploader(
    $input: CreateUploaderInput!
    $condition: ModelUploaderConditionInput
  ) {
    createUploader(input: $input, condition: $condition) {
      username
      userIdentity
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUploader = /* GraphQL */ `
  mutation UpdateUploader(
    $input: UpdateUploaderInput!
    $condition: ModelUploaderConditionInput
  ) {
    updateUploader(input: $input, condition: $condition) {
      username
      userIdentity
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUploader = /* GraphQL */ `
  mutation DeleteUploader(
    $input: DeleteUploaderInput!
    $condition: ModelUploaderConditionInput
  ) {
    deleteUploader(input: $input, condition: $condition) {
      username
      userIdentity
      createdAt
      updatedAt
      owner
    }
  }
`;
